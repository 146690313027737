export const formatListOption=[
  {
    label:'60分钟',
    value:'60'
  },
  {
    label:'90分钟',
    value:'90'
  },
  {
    label:'120分钟',
    value:'120'
  },
]
