export const serveTag = [
  {
    value: '测试标签1',
    label: '测试标签1',
  },
  {
    value: '测试标签2',
    label: '测试标签2',
  }
]

export const serveCategory = [
  {
    value: '1',
    label: '测试分类1',
  },
  {
    value: '2',
    label: '测试分类2',
  }
]

export const serveTimeLength= [
  {
    value: '60',
    label: '60分钟',
  },
  {
    value: '90',
    label: '90分钟',
  },
  {
    value: '120',
    label: '120分钟',
  },
]



export const serveJobLvId= [
  {
    value: '1',
    label: '店长',
  },
  {
    value: '2',
    label: '高级理疗师',
  },
  {
    value: '3',
    label: '普通理疗师',
  },
]


export const categoryProps={
  checkStrictly: true,
  label:'kindName',
  value:'kindId',
}

export const payOptions=[
  {
    label:'百分比',
    value:'1'
  },
  {
    label:'固定金额',
    value:'2'
  }
]
