<template>
  <div class="video-upload">
    <div class="video-list">
      <video
        v-if="fileList.length > 0"
        width="100%"
        controls="controls"
        :src="fileList[0].url"
        class="video"
      ></video>
    </div>
    <el-upload
    :action="UPLOAD_BASE_URL+'/manager/upload'"
      name="file"
      accept=".mp4, .ogg, .flv, .avi, .wmv, .rmvb"
      :on-success="handleUploadSuccess"
      multiple
      list-type="text"
      ref="uploadShopImgRef"
      :before-upload="handleBeforeUpload"
      :file-list="fileList"
      class="el-upload"
      :limit="1"
      :on-exceed="handleExceed"
      :on-remove="handleRemove"
    >
      <el-icon class="icon">
        <plus />
      </el-icon>
    </el-upload>
  </div>
</template>

<script lang="js">
import { defineComponent, reactive, ref, computed } from 'vue'
import { ElMessage } from 'element-plus'
import { UPLOAD_BASE_URL } from '@/common/constant'

export default defineComponent({
  props: {
    fileList: {
      type: Array,
      default() {
        return []
      }
    }
  },
  components: {

  },
  emits: ['handleUploadSuccess','handleUploadRemove'],
  setup(props, { emit }) {
    const uploadShopImgRef = ref(null)
    const videoList = computed(() => props.fileList)
    const handleUploadSuccess = (res, file, fileList) => {
      // console.log(res);
      // console.log(file);
      // console.log(fileList);
      emit('handleUploadSuccess', { ...res, ...file })
    }

    const emptyFileList = () => {
      uploadShopImgRef.value.clearFiles()
    }

    const handleBeforeUpload = (file) => {
      const size = file.size / 1024 / 1024;
      // console.log(file);

      // console.log(size);
    }

    const handleExceed = () => {
      ElMessage({
        message: '视频最多支持上传1个,如需上传,请删除原视频！',
        type: 'warning',
      })
    }

    const handleRemove = (file, fileList) => {
      const url=file.url
      emit('handleUploadRemove',url)
    }

    return {
      handleUploadSuccess,
      uploadShopImgRef,
      emptyFileList,
      handleBeforeUpload,
      videoList,
      handleExceed,
      handleRemove
    }

  }
})
</script>

<style scoped lang="less">
.video-upload {
  display: flex;
  .video-list {
    .video {
      width: 148px;
      height: 148px;
    }
  }
  .el-upload {
    display: flex;
    .icon {
      font-size: 28px;
      color: #8c939d;
    }
    &:deep(.el-upload) {
      background-color: #fbfdff;
      border: 1px dashed #c0ccda;
      border-radius: 6px;
      box-sizing: border-box;
      width: 148px;
      height: 148px;
      cursor: pointer;
      vertical-align: top;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0 10px;
      color: rgb(140, 147, 157);
    }
  }
}
</style>
