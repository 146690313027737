export const breadcrumbList = [
  {
    name: '商品管理',
    path: ''
  },
  {
    name: '服务列表',
    path: '/main/serve/list'
  },
  {
    name: '修改服务',
    path: ''
  }
]

export const updateServeRules = {
  serveName: [{ required: true, message: '请输入服务名称', trigger: 'blur' }],
  serveKind: [{ required: true, message: '请选择服务分类', trigger: 'blur' }],
  serveTags: [{ required: true, message: '请选择标签', trigger: 'blur' }],
  // servePayFlag: [{ required: true, message: '请选择线上预约', trigger: 'blur' }],
  serveLength: [{ required: true, message: '请输入服务时长', trigger: 'blur' }],
  prepare: [{ required: true, message: '请输入筹备时长', trigger: 'blur' }],
  price: [{ required: true, message: '请输入价格', trigger: 'blur' }],
  displayApplet:[{ required: true, message: '请选择是否小程序/网店展示', trigger: 'change' }]
}
