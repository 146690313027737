<template>
  <div v-if="route.path=='/main/serve/update'" class="add">
    <breadcrumb :breadcrumbList="breadcrumbList"></breadcrumb>
    <div class="add-content">
      <add-form></add-form>
    </div>
  </div>
</template>

<script lang="js">
import { defineComponent, reactive, ref,onUnmounted } from 'vue'
import Breadcrumb from '@/components/breadcrumb/breadcrumb.vue'
import { breadcrumbList } from './config'
import AddForm from './cpns/base-info/cpns/add-form/add-form.vue'
import emitter from '@/utils/eventbus'
import { useRoute } from 'vue-router'
export default defineComponent({
  props: {

  },
  components: {
    Breadcrumb,
    AddForm
  },
  setup() {
    const route=useRoute()
    const activeName = ref('a')
    const serveInfo = ref({})

    emitter.on('changeServeUpdateNextClick', (v) => {
      activeName.value='b'
      serveInfo.value=v
    })

    emitter.on('changeServeUpdatePreviousClick',()=>{
      activeName.value='a'
    })

    onUnmounted (()=>{
      emitter.off('changeServeUpdateNextClick')
      emitter.off('changeServeUpdatePreviousClick')
    })
    return {
      breadcrumbList,
      activeName,
      serveInfo,
      route
    }

  }
})
</script>

<style scoped lang="less">
.add {
  .add-content {
    padding: 20px;
    margin: 20px;
    background-color: #fff;
  }
}
</style>




