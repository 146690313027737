<template>
  <div class="add-goods-category-dialog">
    <page-dialog @changeDialogVisible="changeDialogVisible" :dialogVisible="dialogVisible" title="新增服务分类">
      <template #default>
        <div class="category">
          <div class="top-form">
            <el-input size="small" v-model="categoryValue" placeholder="请输入分类名称" />
            <el-button plain color="#409EFF" class="add-btn" type="primary" size="small" @click="handleAddCategoryClick">新增分类</el-button>
          </div>
          <div class="title">
            <div class="name">分类名</div>
            <div class="operate">操作</div>
          </div>
          <div class="category-list">
            <el-tree :data="serveCategoryList" show-checkbox node-key="id" :props="goodsCategoryTreeProps"
              default-expand-all :expand-on-click-node="false" class="tree">
              <template #default="{ node, data }">
                <span>{{ node.label }}</span>
                <div class="custom-tree-node">
                  <span>
                    <a @click="updateCategory(node, data)">更改</a>
                    <a @click="removeCategory(node, data)">删除</a>
                    <a v-if="node.data.parKindId == 0" @click="addSmallCategory(node, data)">新增小类</a>
                  </span>
                </div>
              </template>
            </el-tree>
          </div>
        </div>
      </template>
      <template #footer>
        <div class="footer">
          <!-- <el-button size="small" type="danger">新增大类</el-button> -->
        </div>
        <div></div>
      </template>
    </page-dialog>

    <page-dialog title="更改分类" @changeDialogVisible="changeUpdateNameDialogVisible"
      :dialogVisible="dialogUpdateNameVisible">
      <div class="update-name-form">
        <el-input size="small" v-model="updateCategoryNameValue" placeholder="请输入分类名称" />
        <el-button plain color="#409EFF" size="small" class="sure-btn" type="primary" @click="handleUpdateCategoryNameClick">确定</el-button>
      </div>
    </page-dialog>
  </div>
</template>

<script lang="js">
import { defineComponent, reactive, ref,onUnmounted } from 'vue'
import PageDialog from '@/components/page-dialog/page-dialog.vue'
import { useStore } from 'vuex'
import { useState } from '@/hooks'
import { COMPANY_ID } from '@/common/constant'
import { goodsCategoryTreeProps } from './config'
import emitter from '@/utils/eventbus'
import { popup } from '@/utils/pop-up'
export default defineComponent({
  props: {
    dialogVisible: {
      type: Boolean,
      default: false
    }
  },
  components: {
    PageDialog
  },
  emits: ['changeDialogVisible'],
  setup(props, { emit }) {
    const store = useStore()
    const ruleForm = reactive({
      name: ''
    })

    const categoryValue = ref('')
    const smallCategoryValue = ref('')
    const updateCategoryNameValue = ref('')
    const companyID = ref(COMPANY_ID)
    const goodsCategory = ref([
      {
        name: '分类1'
      },
      {
        name: '分类2'
      },
      {
        name: '分类3'
      }
    ])

    const currentEditCategoryId = ref('')
    const dialogUpdateNameVisible = ref(false)

    const changeUpdateNameDialogVisible = (flag) => {
      dialogUpdateNameVisible.value = flag
    }

    const dialogAddSmallCategoryVisible = ref(false)
    const changeAddSmallCategoryDialogVisible = (flag) => {
      dialogAddSmallCategoryVisible.value = flag
    }

    const changeDialogVisible = (flag) => {
      emit('changeDialogVisible', flag)
    }

    const handleAddCategoryClick = () => {
      store.dispatch('serve/addServeCategoryAction', { kindName: categoryValue.value, companyId: COMPANY_ID })
    }

    const serveCategoryState = useState('serve', ['serveCategoryList'])

    const initPage = () => {
      store.dispatch('serve/getServeCategoryList', { companyId: COMPANY_ID })
    }

    initPage()


    const remove = (node, data) => {
      // console.log(node);
      // console.log(data);
    }

    const updateCategory = (node, data) => {
      updateCategoryNameValue.value = data.kindName
      currentEditCategoryId.value = data.kindId
      dialogUpdateNameVisible.value = true
    }

    const removeCategory = (node, data) => {
      popup('确认删除该类别吗?', () => {
        let obj = {
          companyId: COMPANY_ID,
          kindId: data.kindId,
        }
        store.dispatch('serve/removeServeCategoryInfoAction', obj)
      })

    }



    const handleUpdateCategoryNameClick = () => {
      dialogUpdateNameVisible.value = false
      let obj = {
        companyId: COMPANY_ID,
        kindId: currentEditCategoryId.value,
        kindName: updateCategoryNameValue.value,
      }
      store.dispatch('serve/updateServeCategoryInfoAction', obj)
    }

    const addSmallCategory = (node, data) => {
      currentEditCategoryId.value = data.kindId
      dialogAddSmallCategoryVisible.value = true

    }

    const handleAddSmallCategoryClick = () => {
      dialogAddSmallCategoryVisible.value = false
      let obj = {
        kindName: smallCategoryValue.value,
        companyId: companyID.value,
        parKindId: currentEditCategoryId.value
      }
      store.dispatch('goods/addGoodsCategoryAction', obj)
    }


    emitter.on('refreshServeTagPage', () => {
      initPage()
    })

    onUnmounted(()=>{
      emitter.off('refreshServeTagPage')
    })


    return {
      changeDialogVisible,
      ruleForm,
      goodsCategory,
      categoryValue,
      handleAddCategoryClick,
      ...serveCategoryState,
      goodsCategoryTreeProps,
      remove,
      updateCategory,
      removeCategory,
      changeUpdateNameDialogVisible,
      dialogUpdateNameVisible,
      currentEditCategoryId,
      handleUpdateCategoryNameClick,
      updateCategoryNameValue,
      changeAddSmallCategoryDialogVisible,
      dialogAddSmallCategoryVisible,
      handleAddSmallCategoryClick,
      addSmallCategory,
      smallCategoryValue

    }

  }
})
</script>

<style scoped lang="less">
.add-goods-category-dialog {

  .update-name-form,
  .add-small-category-form {
    display: flex;

    .sure-btn {
      margin-left: 10px;
    }
  }

  .category {
    .top-form {
      display: flex;
      margin-bottom: 10px;

      .add-btn {
        margin-left: 10px;
      }
    }

    .title {
      display: flex;
      background-color: rgb(239, 239, 239);
      line-height: 30px;
      font-size: 13px;
      padding: 0 10px;

      .name {
        flex: 1;
      }

      .operate {
        width: 106px;
      }
    }

    .category-list {
      margin-top: 10px;

      .tree {
        &:deep(.el-tree-node__content) {
          position: relative;

          a {
            color: rgba(42, 130, 228, 1);
            font-size: 13px;
          }
        }

        .custom-tree-node {
          position: absolute;
          right: 0;
          a{
            display: inline-block;
            margin-right: 8px;
          }
        }
      }
    }
  }

  &:deep(.el-select) {
    width: 100%;
  }
}
</style>
