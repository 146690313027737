<template>
  <el-upload :action="UPLOAD_BASE_URL+'/manager/upload'" list-type="picture-card" name="file"
    :on-success="handleUploadSuccess" multiple ref="uploadShopImgRef">
    <el-icon>
      <plus />
    </el-icon>
  </el-upload>
</template>

<script lang="js">
import { defineComponent, reactive, ref } from 'vue'
import { UPLOAD_BASE_URL } from '@/common/constant'

export default defineComponent({
  props: {

  },
  components: {

  },
  emits: ['handleUploadSuccess'],
  setup(props, { emit }) {
    const uploadShopImgRef = ref(null)

    const handleUploadSuccess = (res, fileList) => {
      emit('handleUploadSuccess', { ...res, ...fileList })
    }

    const emptyFileList = () => {
      uploadShopImgRef.value.clearFiles()
    }

    return {
      handleUploadSuccess,
      uploadShopImgRef,
      emptyFileList
    }

  }
})
</script>

<style scoped lang="less">
</style>
